import { template as template_7ae22a235fb446d5a6b6c09e29162d58 } from "@ember/template-compiler";
const SidebarSectionMessage = template_7ae22a235fb446d5a6b6c09e29162d58(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
