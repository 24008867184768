import { template as template_9916c8f7b2d04bd6bae0e7f1a9e3368d } from "@ember/template-compiler";
const FKText = template_9916c8f7b2d04bd6bae0e7f1a9e3368d(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
